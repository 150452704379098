import * as React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import { globalHistory } from "@reach/router";

import Template from "../components/Template";

import * as styles from "./blog.module.scss";

const NotFoundPage = ({ data, location }) => {
  const pageLang = !globalHistory.location.pathname.includes("/fr")
    ? "en"
    : "fr";

  const show = (lang) => {
    if (lang === "en" && pageLang === "en") return { display: "block" };
    else if (lang === "fr" && pageLang === "fr") return { display: "block" };
    else return { display: "none" };
  };

  return (
    <Template location={location}>
      <Helmet>
        <title>{`Charih / ${pageLang === "en" ? "404" : "404"}`}</title>
      </Helmet>
      <div className={styles.container}>
        <div
          style={{ ...show("en"), margin: "200px auto", textAlign: "center" }}
        >
          Oops! This page does not exist!
        </div>
        <div
          style={{ ...show("fr"), margin: "200px auto", textAlign: "center" }}
        >
          Oops! Cette page n'existe pas!
        </div>
      </div>
    </Template>
  );
};

export default NotFoundPage;
